<template>
  <div class="container">
    <!-- <navigation msg="补充个人信息"></navigation> -->
    <div class="header">
      <van-icon @click="goback()" size=".35rem" name="arrow-left" />
      <div class="title1">补充个人信息</div>
    </div>
    <div class="content">
      <div class="item">
        <div class="title">
          姓名
          <span class="span">*</span>
        </div>
        <div class="input">
          <input
            @blur="blur"
            v-model.lazy="name"
            placeholder="请输入员工姓名"
            type="text"
          />
        </div>

        <div class="sexs">
          <div>性别<span class="span">*</span></div>

          <p class="sex">
            <span
              class="nan"
              @click="update('男')"
              :class="{ select: become == '男', select1: become == '女' }"
              >男</span
            >
            <span
              class="nv"
              :class="{ select: become == '女', select1: become == '男' }"
              @click="update('女')"
              >女</span
            >
          </p>
        </div>
      </div>

      <!-- 出生年月 -->
      <div class="flex" @click="selectBirthday">
        <div>
          <div class="title">
            出生年月
            <span class="span">*</span>
          </div>
          <div class="input">
            <input
              disabled
              v-model="birthday"
              placeholder="请输入出生年月"
              type="text"
            />
          </div>
        </div>
        <van-icon size=".35rem" name="arrow" />
      </div>

      <!-- 出生年月选择框 -->
      <van-popup
        v-model="showdata"
        position="bottom"
        :style="{ height: '7.3rem' }"
      >
        <van-datetime-picker
          v-model="currentDate"
          :min-date="minDate"
          type="date"
          title="选择年月日"
          @confirm="confirmdata"
          @cancel="canceldata"
        />
      </van-popup>

      <!-- 手机号 -->
      <div class="item">
        <div class="title">
          手机号
          <span class="span">*</span>
        </div>
        <div class="input">
          <input
            readonly="readonly"
            v-model.lazy="phone"
            placeholder="请输入手机号"
            type="number"
          />
        </div>
      </div>

      <!-- 邮箱 -->
      <div class="item">
        <div class="title">邮箱</div>
        <div class="input">
          <input
            style="width: 5rem"
            @blur="blur2"
            v-model.lazy="email"
            placeholder="请输入邮箱"
            type="text"
          />
        </div>
      </div>

      <!-- 身份证号 -->
      <div class="item">
        <div class="title">身份证号</div>
        <div class="input">
          <input
            @blur="blur1"
            v-model.lazy="card"
            placeholder="请输入身份证号"
            type="text"
          />
        </div>
      </div>

      <!-- 居住所在地 -->
      <div class="flex" @click="isShow">
        <div>
          <div class="title">
            居住所在地
            <!-- <span>*</span> -->
          </div>
          <div class="input">
            <input
              v-model="address"
              disabled
              placeholder="请选择居住所在地"
              type="text"
            />
          </div>
        </div>
        <van-icon size=".35rem" name="arrow" />
      </div>

      <!-- 岗位 -->
      <div class="flex" @click="goposition">
        <div>
          <div class="title">
            岗位
            <span class="span">*</span>
          </div>
          <div class="input">
            <input
              class="positionclass"
              disabled
              v-model="position"
              placeholder="请选择岗位"
              type="text"
            />
          </div>
        </div>
        <van-icon size=".35rem" name="arrow" />
      </div>

      <!-- 政治面貌 -->
      <div class="flex" @click="selectpolitics">
        <div>
          <div class="title">
            政治面貌
            <span class="span">*</span>
          </div>
          <div class="input">
            <input
              disabled
              v-model="politics"
              placeholder="请选择您的政治面貌"
              type="text"
            />
          </div>
        </div>
        <van-icon size=".35rem" name="arrow" />
      </div>

      <van-popup v-model="showpolitics" position="bottom">
        <van-picker
          title="政治面貌 "
          show-toolbar
          :columns="columns1"
          @confirm="onConfirm2"
          @cancel="showpolitics = false"
        />
      </van-popup>
    </div>
    <div class="loginbtn" @click="submit">提交</div>

    <van-popup v-model="show" position="bottom">
      <van-area
        :area-list="areaList"
        @confirm="onConfirm"
        @cancel="show = false"
        :columns-placeholder="['请选择', '全部', '全部']"
      />
    </van-popup>
  </div>
</template>
<script>
import Addressinfo from "../../util/Area";
import { Toast } from "vant";
import getDate from "../../util/dateChange";
import evenBus from "../../util/evenBus";
export default {
  data() {
    return {
      name: "", // 姓名
      become: "男", // 性别
      birthday: "", // 出生年月
      phone: "", // 手机号
      email: "", // 邮箱
      card: "", // 身份证号
      position: "", // 岗位
      politics: "",
      show: false, // 控制居住地显示隐藏
      showdata: false, //控制出生年月日显示隐藏
      showpolitics: false, // 控制政治面貌的显示隐藏
      columns1: ["群众", "党员", "民主党派"], // 政治面貌数据
      currentDate: new Date(),
      minDate: new Date(1970, 1, 1),
      areaList: Addressinfo,
      province: "", // 省
      city: "", // 市
      district: "", // 区
      address: "", // 居住所在地
      perInfo: {},
      position2:""
    };
  },
  mounted() {
    
    this.getInfo();
    evenBus.$on("addinfo_meth", (e) => {
      console.log(e);
      sessionStorage.setItem("addinfo_position",e);
      this.position2 = e;
    });
  },
  destroyed(){
    sessionStorage.removeItem("addinfo_position");
  },
  methods: {
    edit() {
      let perInfo = this.perInfo;
      if (localStorage.getItem("name1") != null) {
        this.name = localStorage.getItem("name1");
      } else {
        this.name =
          perInfo.staff_name == "" || perInfo.staff_name == null
            ? ""
            : perInfo.staff_name;
      }

      if (localStorage.getItem("sex1") != null) {
        this.become = localStorage.getItem("sex1");
      } else {
        this.become =
          perInfo.sex == "" || perInfo.sex == null ? "男" : perInfo.sex;
      }

      if (localStorage.getItem("idcard") != null) {
        this.card = localStorage.getItem("idcard");
      } else {
        this.card =
          perInfo.id_no == "" || perInfo.id_no == null ? "" : perInfo.id_no;
      }

      if (localStorage.getItem("email1") != null) {
        this.email = localStorage.getItem("email1");
      } else {
        this.email =
          perInfo.email == "" || perInfo.email == null ? "" : perInfo.email;
      }

      if (localStorage.getItem("birthday1") != null) {
        this.birthday = localStorage.getItem("birthday1");
      } else {
        this.birthday =
          perInfo.birthday == "" || perInfo.birthday == null
            ? ""
            : perInfo.birthday;
      }

      if (localStorage.getItem("phone1") != null) {
        this.phone = localStorage.getItem("phone1");
      } else {
        // console.log(this.perInfo)
        this.phone =
          perInfo.mobile == "" || perInfo.mobile == null ? "" : perInfo.mobile;
      }

      if (
        localStorage.getItem("province1") != null &&
        localStorage.getItem("city1") != null &&
        localStorage.getItem("district1") != null
      ) {
        this.address =
          localStorage.getItem("province1") +
          localStorage.getItem("city1") +
          localStorage.getItem("district1");
      } else {
        this.address =
          perInfo.address == "" || perInfo.address == null
            ? " "
            : perInfo.address;
      }

      if (localStorage.getItem("position1")) {
        this.position = localStorage.getItem("position1");
      } else if(perInfo.position){
        this.position =perInfo.position;
      }else {
        this.position = sessionStorage.getItem("addinfo_position");
      }

      if (localStorage.getItem("politics1") != null) {
        this.politics = localStorage.getItem("politics1");
      } else {
        this.politics = "";
      }
    },
    getInfo() {
      if (sessionStorage.getItem("messageType") != "messageType") {
        let id = sessionStorage.getItem("companyId");
        // console.log(id)
        this.$http
          .post("/user/v1/personal_info/perInfo", {
            reqType: "perInfo",
            companyId: id,
          })
          .then((res) => {
            res = JSON.parse(res.data);
            if (res.code == 0) {
              // console.log(res)
              res.data.perInfo.address =
                res.data.perInfo.city +
                res.data.perInfo.province +
                res.data.perInfo.district;
              this.perInfo = res.data.perInfo;
              this.edit();
            } else {
              Toast.fail(res.msg);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        let id = sessionStorage.getItem("e_id");
        this.$http
          .post("/user/v1/personal_info/perInfo", {
            reqType: "perInfo",
            companyId: id,
          })
          .then((res) => {
            res = JSON.parse(res.data);
            if (res.code == 0) {
              console.log(res);
              res.data.perInfo.address =
                res.data.perInfo.city +
                res.data.perInfo.province +
                res.data.perInfo.district;
              this.perInfo = res.data.perInfo;
              this.edit();
            } else {
              Toast.fail(res.msg);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    blur3() {
      if (this.phone == "") {
        Toast("手机号不能为空");
        return;
      }
      let reg =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      let bool = reg.test(this.phone);
      if (bool == false) {
        Toast("请输入正确的手机号码");
      } else {
        localStorage.setItem("phone1", this.phone);
      }
    },
    update(type) {
      this.become = type;
      localStorage.setItem("sex1", type);
    },
    onConfirm2(value) {
      this.politics = value;
      localStorage.setItem("politics1", value);
      this.showpolitics = false;
    },
    confirmdata(value) {
      this.showdata = false;
      value = getDate(value);
      this.birthday = value;
      localStorage.setItem("birthday1", value);
      console.log(value);
    },
    selectpolitics() {
      this.showpolitics = true;
    },
    canceldata() {
      this.showdata = false;
    },
    selectBirthday() {
      this.showdata = true;
    },
    blur() {
      let name = this.name;
      if (name == "") {
        Toast("姓名不能为空");
        return;
      }
      localStorage.setItem("name1", name);
    },
    blur1() {
      let card = this.card;
      let reg = /^(\d{18,18}|\d{15,15}|\d{17,17}X)$/;
      let bool = reg.test(card);
      if (card != "") {
        if (bool == false) {
          Toast("请输入正确的身份证号");
        }
      }
      localStorage.setItem("idcard", card);
    },
    blur2() {
      let email = this.email;
      let reg =
        /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/;
      let bool = reg.test(this.email);
      if (email != "") {
        if (bool == false) {
          Toast("请输入正确的邮箱");
        }
      }
      localStorage.setItem("email1", email);
    },

    goback() {
      if (sessionStorage.getItem("messageType") == "messageType") {
        this.$router.push("./message");
      } else {
        this.$router.push("./companyDetail");
      }
    },
    goposition() {
      this.$router.push({ path: "./station", query: { type: "addInfo" } });
    },
    isShow() {
      this.show = true;
    },
    onConfirm(value) {
      this.show = false;
      if (value.length == 3) {
        this.province = value[0].name;
        this.city = value[1].name;
        this.district = value[2].name;
      } else {
        Toast("请选择完整");
      }
      localStorage.setItem("province1", this.province);
      localStorage.setItem("city1", this.city);
      localStorage.setItem("district1", this.district);

      this.address = this.province + this.city + this.district;
    },
    submit() {
      let {
        name,
        birthday,
        position,
        card,
        email,
        politics,
        become,
        province,
        city,
        district,
      } = this;
      province = localStorage.getItem("province1");
      city = localStorage.getItem("city1");
      district = localStorage.getItem("district1");
      if (name == "") {
        Toast("姓名不能为空");
        return;
      }
      if (position == "") {
        Toast("职务不能为空");
        return;
      }

      if (birthday == "") {
        Toast("出生日期不能为空");
        return;
      }

      if (position == "") {
        Toast("职位不能为空");
        return;
      }

      if (politics == "") {
        Toast("政治面貌不能为空");
        return;
      }

      if (sessionStorage.getItem("messageType") == "messageType") {
        let companyId = sessionStorage.getItem("e_id");

        console.log(companyId);
        this.$http
          .post(".//user/v1/personal_info/perInfoEdit", {
            reqType: "perInfoEdit",
            perInfoId: "-1",
            name: name,
            idNo: card,
            position: position,
            email: email,
            province: province,
            city: city,
            district: district,
            companyId: companyId,
          })
          .then((res) => {
            console.log(res);
            res = JSON.parse(res.data);
            if (res.code == 0) {
              Toast("保存成功");
              //  this.$router.go(-1)
              sessionStorage.removeItem("messageType");
              this.$router.push("./message");
            } else {
              Toast.fail(res.msg);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        let id = sessionStorage.getItem("perInfoId");
        this.$http
          .post("/user/v1/personal_info/perInfoEdit", {
            reqType: "perInfoEdit",
            perInfoId: id,
            name: name,
            sex: become,
            birthday: birthday,
            politics: politics,
            idNo: card,
            position: position,
            email: email,
            province: province,
            city: city,
            district: district,
          })
          .then((res) => {
            res = JSON.parse(res.data);
            if (res.code == 0) {
              Toast.success("编辑成功");
              setTimeout(() => {
                this.$router.push("./companyDetail");
                // this.$router.push("./user")
              }, 1000);
            } else {
              Toast.fail(res.msg);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
  },
};
</script>
<style scoped>
.positionclass{
  width: 100%;
}
.sexs {
  display: flex;
  align-items: center;
  border-bottom: solid 0.01rem #eee;
  font-size: 0.3rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #697386;
  margin-top: 0.3rem;
}
.sexs .sex {
  width: 3.07rem;
  height: 0.74rem;
  border: solid 0.01rem #f55613;
  font-size: 0.3rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #f55613;
  line-height: 0.74rem;
  margin-left: 2.5rem;
  display: flex;
  margin-bottom: 0.3rem;
}

.sexs .sex .nv {
  display: block;
  width: 1.54rem;
  color: #ffffff;

  text-align: center;
}

.sexs .sex .nan {
  display: block;
  width: 1.54rem;
  text-align: center;
}

.select {
  color: #fff;
  background: #f55613;
}
.select1 {
  color: #f55613 !important;
  background: #fff;
}

.header {
  height: 1rem;
  display: flex;
  align-items: center;

  background-color: #fff;
  padding: 0 0.2rem;
}
.title1 {
  margin-left: 2.5rem;
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}
.loginbtn {
  width: 6.86rem;
  height: 0.96rem;
  background: linear-gradient(90deg, #f55613 0%, #fc9e46 100%);
  border-radius: 0.48rem;
  line-height: 0.96rem;
  font-size: 0.36rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin: 0 auto;
  margin-top: 0.5rem;
  margin-bottom: 3rem;
  text-align: center;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.01rem solid #ddd;
  margin-bottom: 0.2rem;
}
.info {
  font-size: 0.34rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  border-bottom: 0.02rem solid #ddd;
  padding-bottom: 0.15rem;
}
.item {
  margin-bottom: 0.2rem;
}
.title {
  height: 0.9rem;
  line-height: 0.9rem;
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #676767;
}
.span {
  color: red;
  margin-left: 0.1rem;
}
.container {
  position: fixed;
  height: 100vh;
  width: 100%;
  background-color: #fff;
  top: 0;
  bottom: 0;
  overflow-y: scroll;
  overflow-x: hidden;
}
.content {
  margin: 0.4rem 0.4rem 0;
}
.input input {
  border: none;
  font-size: 0.34rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666;
  background-color: #fff;
}
.input {
  padding-bottom: 0.3rem;
  border-bottom: 0.01rem solid #ddd;
}
.input1 input {
  border: none;
  font-size: 0.34rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #cacaca;
  background-color: #fff;
}
.input1 {
  padding-bottom: 0.3rem;
}
</style>